import { useEffect, useState } from "react";
import { getEasyjetV2AllFlights, getEasyjetV2Flights, getListOfUniqueArrivalsEasyjet } from "../Helpers/ApiResponseEasyjet";
import { getListOfUniqueArrivalsRyanair, getRyanairDateFlights, getRyanairFlights } from "../Helpers/ApiResponseRyanair";
import { getListOfUniqueArrivalsVueling, getVuelingAllFlights, getVuelingFlights } from "../Helpers/ApiResponseVueling";
import * as Helpers from "../Helpers/Helpers";
import { CustomDropdown } from "../components/CustomDropdown";
import { FlightSelection } from "../components/FlightSelection";
import { HomeHeader } from "../components/HomeHeader";
import { Loading } from "../components/Loading";
import { SquirrelButton } from "../components/SquirrelButton";
import { TableFlights } from "../components/TableFlights";
import { Airline, CustomFlight, DropdownItems } from "../types/CustomTypes";

const Home = () => {
    const [listOfIatasDdl, setlistOfIatasDdl] = useState<DropdownItems[]>([]);
    const [listOfArrivalsDdl, setlistOfArrivalsDdl] = useState<DropdownItems[]>();

    const [sortDeparture, setsortDeparture] = useState<number>(1);
    const [sortReturn, setsortReturn] = useState<number>(1);
    const [noDecimalsDeparture, setnoDecimalsDeparture] = useState<boolean>(false);
    const [noDecimalsReturn, setnoDecimalsReturn] = useState<boolean>(false);

    const [isLoadingFlights, setisLoadingFlights] = useState(false);

    const [departureSelected, setdepartureSelected] = useState<string>();
    const [arrivalSelected, setarrivalSelected] = useState<string>();

    const [flightsForRoute, setflghtsForRoute] = useState<CustomFlight[]>();
    const [flightsReturnForRoute, setflghtsReturnForRoute] = useState<CustomFlight[]>();
    const [allflightsForRoute, setallflghtsForRoute] = useState<CustomFlight[]>();

    const [flightSelectedDeparture, setflightSelectedDeparture] = useState<CustomFlight | undefined>(undefined);
    const [flightSelectedArrival, setflightSelectedArrival] = useState<CustomFlight | undefined>(undefined);

    useEffect(() => {
        const getData = async () => {
            setlistOfIatasDdl(await Helpers.getAllIataDdl());
        };

        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            const _listOfArrivalsRyanair = await getListOfUniqueArrivalsRyanair(departureSelected!);
            const _listOfArrivalsEasyjet = await getListOfUniqueArrivalsEasyjet(departureSelected!);
            const _listOfArrivalsVueling = await getListOfUniqueArrivalsVueling(departureSelected!);

            const _listOfArrivalsUnique = [..._listOfArrivalsRyanair, ...(_listOfArrivalsEasyjet ?? []), ..._listOfArrivalsVueling].filter(
                (obj1, i, arr) => arr.findIndex((obj2) => obj2.code === obj1.code) === i
            );

            if (_listOfArrivalsUnique.length > 0) {
                var _ddlItems1: string[] = ["ALL"];
                var _ddlItems2: string[] = ["*Show me the cheapest locations*"];
                _listOfArrivalsUnique.forEach((arrivalairport) => {
                    var _ddlItem1 = arrivalairport.code;
                    var _ddlItem2 = arrivalairport.name;
                    _ddlItems1.push(_ddlItem1);
                    _ddlItems2.push(_ddlItem2);
                });
                const _ddlArrivalItems = Helpers.GetDdlItems(_ddlItems1, _ddlItems2);
                setlistOfArrivalsDdl(_ddlArrivalItems);
            }
        };

        getData();

        restoreSorting();

        return () => {};
    }, [departureSelected]);

    useEffect(() => {
        return () => {};
    });

    useEffect(() => {
        loadFlights();
        return () => {};
    }, [sortDeparture, sortReturn]);

    const dropdownDepartureChange = (_iataId: string) => {
        setdepartureSelected(_iataId);
    };

    const dropdownArrivalChange = async (_iataId: string) => {
        setflghtsForRoute(undefined);
        setallflghtsForRoute(undefined);
        setflghtsReturnForRoute(undefined);

        setarrivalSelected(_iataId);
    };

    function sortDepartureClick(): void {
        if (sortDeparture < 3) setsortDeparture(sortDeparture + 1);
        else setsortDeparture(1);
    }

    function sortReturnClick(): void {
        if (sortReturn < 3) setsortReturn(sortReturn + 1);
        else setsortReturn(1);
    }

    function noDecimalsDepartureClick(): void {
        setnoDecimalsDeparture(!noDecimalsDeparture);
    }

    function noDecimalsReturnClick(): void {
        setnoDecimalsReturn(!noDecimalsReturn);
    }

    function restoreSorting() {
        setsortDeparture(1);
        setsortReturn(1);
    }

    const getDataAllFlights = async () => {
        var _flightsForAllRoutes: CustomFlight[] = [];

        const _ryanairAll: CustomFlight[] = await getRyanairDateFlights(departureSelected!);
        const _easyjetAll: CustomFlight[] = await getEasyjetV2AllFlights(departureSelected!);
        const _vuelingAll: CustomFlight[] = await getVuelingAllFlights(departureSelected!);

        _flightsForAllRoutes = [..._ryanairAll, ..._easyjetAll, ..._vuelingAll];

        var _flightsForAllRoutesSorted: CustomFlight[] = [];
        switch (sortDeparture) {
            case 2:
                _flightsForAllRoutesSorted = _flightsForAllRoutes.sort((a, b) => a.Price! - b.Price!);
                break;

            case 3:
                _flightsForAllRoutesSorted = _flightsForAllRoutes
                    .sort((a, b) => a.Date.getTime() - b.Date.getTime())
                    .sort((a, b) => (Object.keys(Airline).indexOf(a.Airline) > Object.keys(Airline).indexOf(b.Airline) ? 1 : -1));
                break;

            default:
                _flightsForAllRoutesSorted = _flightsForAllRoutes.sort((a, b) => a.Date.getTime() - b.Date.getTime());
                break;
        }

        setallflghtsForRoute(_flightsForAllRoutesSorted.filter((v, i, a) => a.indexOf(v) === i));
    };

    const getDataFlights = async () => {
        const _listOfAirlines = await Helpers.checkIfRouteBelongsToAirlines(departureSelected!, arrivalSelected!);

        var _flightsForRoute: CustomFlight[] = [];
        var _flightsReturnForRoute: CustomFlight[] = [];

        await Promise.all(
            _listOfAirlines.map(async (_airline) => {
                var _responseFlight: CustomFlight[] = await switchAirlineArray(_airline);
                var _responseReturnFlight: CustomFlight[] = await switchAirlineReturnArray(_airline);
                _flightsForRoute = _flightsForRoute.concat(_responseFlight);
                _flightsReturnForRoute = _flightsReturnForRoute.concat(_responseReturnFlight);
            })
        );

        async function switchAirlineArray(element: Airline): Promise<CustomFlight[]> {
            switch (element) {
                case Airline.Ryanair:
                    return Promise.all(await getRyanairFlights(departureSelected!, arrivalSelected!));

                case Airline.Easyjet:
                    return await getEasyjetV2Flights(departureSelected!, arrivalSelected!, true);

                case Airline.Vueling:
                    return await getVuelingFlights(departureSelected!, arrivalSelected!);
            }
        }

        async function switchAirlineReturnArray(element: Airline): Promise<CustomFlight[]> {
            switch (element) {
                case Airline.Ryanair:
                    return Promise.all(await getRyanairFlights(arrivalSelected!, departureSelected!));

                case Airline.Easyjet:
                    return await getEasyjetV2Flights(departureSelected!, arrivalSelected!, false);

                case Airline.Vueling:
                    return await getVuelingFlights(arrivalSelected!, departureSelected!);
            }
        }

        var _flightsForRouteSorted: CustomFlight[] = [];
        switch (sortDeparture) {
            case 2:
                _flightsForRouteSorted = _flightsForRoute.sort((a, b) => a.Price! - b.Price!);
                break;

            case 3:
                _flightsForRouteSorted = _flightsForRoute
                    .sort((a, b) => a.Date.getTime() - b.Date.getTime())
                    .sort((a, b) => (Object.keys(Airline).indexOf(a.Airline) > Object.keys(Airline).indexOf(b.Airline) ? 1 : -1));
                break;

            default:
                _flightsForRouteSorted = _flightsForRoute.sort((a, b) => a.Date.getTime() - b.Date.getTime());
                break;
        }

        var _flightsReturnForRouteSorted: CustomFlight[] = [];
        switch (sortReturn) {
            case 2:
                _flightsReturnForRouteSorted = _flightsReturnForRoute.sort((a, b) => a.Price! - b.Price!);
                break;

            case 3:
                _flightsReturnForRouteSorted = _flightsReturnForRoute
                    .sort((a, b) => a.Date.getTime() - b.Date.getTime())
                    .sort((a, b) => (Object.keys(Airline).indexOf(a.Airline) > Object.keys(Airline).indexOf(b.Airline) ? 1 : -1));
                break;

            default:
                _flightsReturnForRouteSorted = _flightsReturnForRoute.sort((a, b) => a.Date.getTime() - b.Date.getTime());
                break;
        }

        setflghtsForRoute(_flightsForRouteSorted);
        setflghtsReturnForRoute(_flightsReturnForRouteSorted);
    };

    async function loadFlights(_restoreSorting: boolean = false) {
        setflghtsForRoute(undefined);
        setallflghtsForRoute(undefined);
        setflghtsReturnForRoute(undefined);
        setisLoadingFlights(true);
        setisLoadingFlights(true);
        setflightSelectedDeparture(undefined);
        setflightSelectedArrival(undefined);

        if (arrivalSelected && departureSelected) {
            if (arrivalSelected === "ALL") {
                await getDataAllFlights();
            } else {
                await getDataFlights();
            }
        }

        if (_restoreSorting) restoreSorting();
        setisLoadingFlights(false);
    }

    async function searchFlightsClick() {
        loadFlights(true);
    }

    function departureflightClick(_flightSelected: CustomFlight) {
        if (_flightSelected === flightSelectedDeparture) setflightSelectedDeparture(undefined);
        else {
            setflightSelectedDeparture(_flightSelected);
        }
    }

    function arrivalflightClick(_flightSelected: CustomFlight) {
        if (_flightSelected === flightSelectedArrival) setflightSelectedArrival(undefined);
        else {
            setflightSelectedArrival(_flightSelected);
        }
    }

    return (
        <div className="homePage">
            <HomeHeader></HomeHeader>

            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%", display: "flex" }}>
                    <CustomDropdown ddItems={listOfIatasDdl} onChangeDrop={dropdownDepartureChange} watermark="Select Departure Airport"></CustomDropdown>
                </div>

                <div style={{ width: "50%", display: "flex" }}>
                    {listOfArrivalsDdl ? <CustomDropdown ddItems={listOfArrivalsDdl} onChangeDrop={dropdownArrivalChange} watermark="Select Arrival Airport"></CustomDropdown> : null}
                </div>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <SquirrelButton text="Search Flights" onClick={() => searchFlightsClick()} isDisabled={listOfIatasDdl.length < 1}></SquirrelButton>
            </div>

            {isLoadingFlights ? <Loading></Loading> : null}

            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%", display: "flex" }}>
                    {(flightsForRoute || allflightsForRoute) && !flightSelectedDeparture ? <SquirrelButton text="SORT" onClick={() => sortDepartureClick()}></SquirrelButton> : null}
                    {flightsForRoute || allflightsForRoute ? <SquirrelButton text={noDecimalsDeparture ? "Decimals" : "No Decimals"} onClick={() => noDecimalsDepartureClick()}></SquirrelButton> : null}
                </div>

                <div style={{ width: "50%", display: "flex" }}>
                    {flightsReturnForRoute && !flightSelectedArrival ? <SquirrelButton text="SORT" onClick={() => sortReturnClick()}></SquirrelButton> : null}
                    {flightsReturnForRoute ? <SquirrelButton text={noDecimalsReturn ? "Decimals" : "No Decimals"} onClick={() => noDecimalsReturnClick()}></SquirrelButton> : null}
                </div>
            </div>

            {flightSelectedDeparture && flightSelectedArrival ? (
                <div style={{ display: "flex", width: "100%" }}>
                    <FlightSelection departureflight={flightSelectedDeparture} arrivalflight={flightSelectedArrival}></FlightSelection>
                </div>
            ) : null}

            <div style={{ display: "flex", width: "100%" }}>
                {flightsForRoute && flightsReturnForRoute ? (
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "50%" }}>
                            <TableFlights
                                flightsforroute={flightsForRoute}
                                sortedcolumn={sortDeparture}
                                nodecimals={noDecimalsDeparture}
                                onclickflight={departureflightClick}
                                timestart={flightSelectedArrival?.Date}></TableFlights>
                        </div>
                        <div style={{ width: "50%" }}>
                            <TableFlights
                                flightsforroute={flightsReturnForRoute}
                                sortedcolumn={sortReturn}
                                nodecimals={noDecimalsReturn}
                                onclickflight={arrivalflightClick}
                                timeend={flightSelectedDeparture?.Date}></TableFlights>
                        </div>
                    </div>
                ) : null}

                {allflightsForRoute ? (
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ width: "100%" }}>
                            <TableFlights flightsforroute={allflightsForRoute} sortedcolumn={sortDeparture} nodecimals={noDecimalsDeparture} showLocation={true}></TableFlights>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Home;
