import React from "react";
import styled from "styled-components";
import { mediaQuery } from "../Helpers/Helpers";
import { DropdownItems } from "../types/CustomTypes";

interface Props {
    ddItems: DropdownItems[];
    onChangeDrop: Function;
    selectAll?: string;
    watermark?: string;
}

const Container = styled.div<{}>`
    width: fit-content;
    padding: 10px;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        padding: 5px;
    }
`;

const CustomSelect = styled.select<{}>`
    width: 100%;
    padding: 5px;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        font-size: 12px;
    }
`;

export const CustomDropdown: React.FC<Props> = ({ ddItems, onChangeDrop, selectAll, watermark }) => {
    return (
        <Container>
            {ddItems.length > 0 ? (
                <CustomSelect defaultValue={""} onChange={(event) => onChangeDrop(event.target.value)}>
                    <option value="" disabled hidden>
                        {watermark ? watermark : "Select"}
                    </option>
                    {selectAll ? (
                        <option key="All" value={0}>
                            {selectAll}
                        </option>
                    ) : null}
                    {ddItems?.map((item, index) => {
                        return (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        );
                    })}
                </CustomSelect>
            ) : (
                <CustomSelect defaultValue={""} onChange={(event) => onChangeDrop(event.target.value)} disabled>
                    <option value="" disabled hidden>
                        {"Please wait"}
                    </option>
                </CustomSelect>
            )}
        </Container>
    );
};
