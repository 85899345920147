import moment from "moment";
import { Airline, ArrivalAirport, CustomFlight, CustomTime, SquirrelIata } from "../types/CustomTypes";
import { EasyjetDestinationCountriesResponse, EasyjetResponseAirport, EasyjetResponseFlights, EasyjetResponseRoutes, Flight } from "../types/EasyjetResponseTypes";
import { ListOfEuropeanAirportCodes, ListOfEuropeanAirportNames } from "../types/Iatas";
import { getApiDataEasyjet, getApiDataEasyjetAvailabilityV2 } from "./ApiData";
import { currency, easyjetArrivalUrl, easyjetAvailabilityUrl, easyjetDestinationCountriesUrl } from "./Constants";
import { getDateInFormat, stringFormat } from "./Helpers";

export async function ManageEasyjetAirportSessionData(): Promise<EasyjetResponseAirport> {
    /* var _output: EasyjetResponseAirport;
    const storedSession = sessionStorage.getItem("easyjetAirportResponse");

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as EasyjetResponseAirport;
    } else {
        _output = await getApiDataEasyjet(easyjetAirportUrl);
        sessionStorage.setItem("easyjetAirportResponse", JSON.stringify(_output));
    }
    return _output; */
    const _flights: Flight[] = [];
    const temp: EasyjetResponseAirport = { Flights: _flights };
    return temp;
}

export async function ManageEasyjetRouteSessionData(_departureEasyjet: string, _arrivalEasyjet: string = ""): Promise<EasyjetResponseRoutes> {
    var _output: EasyjetResponseRoutes;
    const storedSession = sessionStorage.getItem("easyjetRouteResponse" + _departureEasyjet);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as EasyjetResponseRoutes;
    } else {
        if (_arrivalEasyjet.length > 0) {
            _output = await getApiDataEasyjet(_arrivalEasyjet);
            sessionStorage.setItem("easyjetRouteResponse" + _departureEasyjet, JSON.stringify(_output));
        }
    }
    return _output!;
}

export async function ManageEasyjetFlightSessionData(_departureEasyjet: string, _arrivalEasyjet: string = ""): Promise<EasyjetResponseFlights> {
    var _output: EasyjetResponseFlights;
    const storedSession = sessionStorage.getItem("easyjetFlightResponse" + _departureEasyjet + _arrivalEasyjet);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as EasyjetResponseFlights;
    } else {
        _output = await getApiDataEasyjetAvailabilityV2(_departureEasyjet, _arrivalEasyjet, getDateInFormat(), getDateInFormat(undefined, CustomTime.Year, 1));
        sessionStorage.setItem("easyjetFlightResponse" + _departureEasyjet + _arrivalEasyjet, JSON.stringify(_output));
    }
    return _output!;
}

export async function getEasyjetFlights(_departure: string, _arrival: string, _isOutbound: boolean): Promise<CustomFlight[]> {
    const _easyjetFlightResponse = await ManageEasyjetFlightSessionData(_departure, _arrival);

    var _output: CustomFlight[] = [];

    const _whereToLook = _isOutbound ? _easyjetFlightResponse.departureFlights : _easyjetFlightResponse.returnFlights;

    _whereToLook.forEach((_flight) => {
        const dateToday = new Date();
        const dateFlight = new Date(new Date(_flight.date));

        if (dateToday < dateFlight) {
            var _customFlight: CustomFlight = {
                Date: dateFlight,
                Destination: _isOutbound ? _arrival : _departure,
                Origin: _isOutbound ? _departure : _arrival,
                Price: _flight.price,
                Currency: currency,
                Airline: Airline.Easyjet,
                DateHasNoTme: true,
            };
            _output.push(_customFlight);
        }
    });

    return _output!;
}

export async function getEasyjetAllFlights(_departureSelected: string) {
    var _output: CustomFlight[] = [];
    const _easyjetAirportResponse: EasyjetResponseAirport = await ManageEasyjetAirportSessionData();

    _easyjetAirportResponse?.Flights.forEach((element) => {
        // const dateToday = new Date();
        const _flightDate = new Date(element.DepartureDate);
        // var diff = Math.abs(_flightDate.getTime() - dateToday.getTime());
        // var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        // if (diffDays < 30 && element.OriginIata == _departureSelected) {
        if (element.OriginIata === _departureSelected) {
            var _customFlight: CustomFlight = {
                Date: _flightDate,
                Destination: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(element.DestinationIata)],
                Origin: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(element.OriginIata)],
                Price: element.Price,
                Currency: currency,
                Airline: Airline.Easyjet,
            };
            _output.push(_customFlight);
        }
        // }
    });

    return _output!;
}

export async function getEasyjetDateFlights(_departureSelected: string, _departureDate: Date, _arrivalDate: Date): Promise<CustomFlight[]> {
    var _output: CustomFlight[] = [];
    const _easyjetAirportResponse: EasyjetResponseAirport = await ManageEasyjetAirportSessionData();

    _easyjetAirportResponse?.Flights.forEach((element) => {
        const _flightDate = new Date(element.DepartureDate);
        const _isDateInRange = _flightDate.getTime() <= _arrivalDate.getTime() && _flightDate.getTime() >= _departureDate.getTime();
        const _isFlightFromDeparture = element.OriginIata === _departureSelected;

        if (_isFlightFromDeparture && _isDateInRange) {
            var _customFlight: CustomFlight = {
                Date: _flightDate,
                Destination: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(element.DestinationIata)],
                Origin: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(element.OriginIata)],
                Price: element.Price,
                Currency: currency,
                Airline: Airline.Easyjet,
            };
            _output.push(_customFlight);
        }
    });

    return _output!;
}

export async function getListOfUniqueArrivalsEasyjet(_departureSelected: string) {
    const _easyjetAiportResponse = await ManageEasyjetAirportSessionData();

    if (_easyjetAiportResponse === undefined) return undefined;

    const _isEasyjetAirport = _easyjetAiportResponse.Flights?.find((a) => a.OriginIata === _departureSelected);
    var _arrivalURLEasyjet = stringFormat(easyjetArrivalUrl, _departureSelected);
    var _listOfArrivals: ArrivalAirport[] = [];

    if (_isEasyjetAirport) {
        const _easyjetResponse: EasyjetResponseRoutes = await ManageEasyjetRouteSessionData(_departureSelected!, _arrivalURLEasyjet);

        _easyjetResponse.Routes.forEach((element) => {
            var _easyjetCustomAirport: ArrivalAirport = {
                code: element.destination,
                name: _easyjetResponse.Airports.find((a) => a.AirportCode === element.destination)!.NameContent.AirportName,
            };
            _listOfArrivals.push(_easyjetCustomAirport);
        });
    }

    const _listOfArrivalsUnique = _listOfArrivals.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.code === obj1.code) === i);
    return _listOfArrivalsUnique;
}

export async function getEasyjetDateFlightsTeste(_departureSelected: string, _departureDate: Date, _arrivalDate: Date) {
    var _output: CustomFlight[] = [];
    var _arrivalURLEasyjet = stringFormat(easyjetArrivalUrl, _departureSelected);
    const _easyjetResponse: EasyjetResponseRoutes = await ManageEasyjetRouteSessionData(_departureSelected, _arrivalURLEasyjet);

    _easyjetResponse?.Routes.forEach((route) => {
        const _destinationAirport = route.destination;
        const _originAirport = route.origin;

        route.flights.forEach((flight) => {
            const _flightDate = new Date(flight.date);
            const _isDateInRange = _flightDate.getTime() <= _arrivalDate.getTime() && _flightDate.getTime() >= _departureDate.getTime();

            if (_isDateInRange) {
                var _customFlight: CustomFlight = {
                    Date: _flightDate,
                    Destination: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(_destinationAirport)],
                    Origin: ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(_originAirport)],
                    Price: flight.price,
                    Currency: currency,
                    Airline: Airline.Easyjet,
                };
                _output.push(_customFlight);
            }
        });
    });

    return _output!;
}

//#region V2
export async function getEasyjetV2DestinationCountries() {
    var _output: EasyjetDestinationCountriesResponse;
    const storedSession = sessionStorage.getItem("easyjetDestinationCountries");

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as EasyjetDestinationCountriesResponse;
    } else {
        _output = await getApiDataEasyjet(easyjetDestinationCountriesUrl);
        sessionStorage.setItem("easyjetDestinationCountries", JSON.stringify(_output));
    }
    return _output;
}

export async function getEasyjetV2Airports() {
    await getEasyjetV2DestinationCountries();
    var _output: SquirrelIata[] = [];
    const storedSession = sessionStorage.getItem("easyjetDestinationCountries");

    if (storedSession && storedSession !== "undefined") {
        const _easyjetDestinationCountries = JSON.parse(storedSession) as EasyjetDestinationCountriesResponse;

        _easyjetDestinationCountries?.destinations.forEach((destination) => {
            destination?.airportCodes.forEach((airportCode) => {
                const _airportName = ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(airportCode)];
                if (_airportName) {
                    var _customFlight: SquirrelIata = {
                        code: airportCode,
                        name: _airportName,
                    };
                    _output.push(_customFlight);
                }
            });
        });
    }
    return _output;
}

export async function getEasyjetV2ListOfUniqueArrivals(_departureSelected: string) {
    const _easyjetV2AiportResponse = await getEasyjetV2Airports();

    const _isEasyjetAirport = _easyjetV2AiportResponse?.find((a) => a.code === _departureSelected);
    var _arrivalURLEasyjet = stringFormat(easyjetArrivalUrl, _departureSelected);
    var _listOfArrivals: ArrivalAirport[] = [];

    if (_isEasyjetAirport) {
        const _easyjetResponse: EasyjetResponseRoutes = await ManageEasyjetRouteSessionData(_departureSelected!, _arrivalURLEasyjet);

        _easyjetResponse.Routes.forEach((element) => {
            var _easyjetCustomAirport: ArrivalAirport = {
                code: element.destination,
                name: _easyjetResponse.Airports.find((a) => a.AirportCode === element.destination)!.NameContent.AirportName,
            };
            _listOfArrivals.push(_easyjetCustomAirport);
        });
    }

    const _listOfArrivalsUnique = _listOfArrivals.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.code === obj1.code) === i);
    return _listOfArrivalsUnique;
}

export async function ManageEasyjetV2FlightSessionData(_departure: string, _arrival: string): Promise<EasyjetResponseFlights> {
    var _easyjetV2FlightsURL = stringFormat(easyjetAvailabilityUrl, _departure, _arrival, getDateInFormat(), getDateInFormat(undefined, CustomTime.Year, 1));
    var _output: EasyjetResponseFlights;
    const storedSession = sessionStorage.getItem("easyjetV2FlightResponse" + _departure + _arrival);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as EasyjetResponseFlights;
    } else {
        _output = await getApiDataEasyjet(_easyjetV2FlightsURL);
        sessionStorage.setItem("easyjetV2FlightResponse" + _departure + _arrival, JSON.stringify(_output));
    }
    return _output!;
}

export async function getEasyjetV2Flights(_departure: string, _arrival: string, _isOutbound: boolean): Promise<CustomFlight[]> {
    const _easyjetFlightResponse = await ManageEasyjetV2FlightSessionData(_departure, _arrival);

    var _output: CustomFlight[] = [];

    const _whereToLook = _isOutbound ? _easyjetFlightResponse.departureFlights : _easyjetFlightResponse.returnFlights;

    _whereToLook.forEach((_flight) => {
        const dateToday = new Date();
        const dateFlight = new Date(new Date(_flight.date));

        if (dateToday < dateFlight) {
            var _customFlight: CustomFlight = {
                Date: dateFlight,
                Destination: _isOutbound ? _arrival : _departure,
                Origin: _isOutbound ? _departure : _arrival,
                Price: _flight.price,
                Currency: currency,
                Airline: Airline.Easyjet,
                DateHasNoTme: true,
            };
            _output.push(_customFlight);
        }
    });

    return _output!;
}

export async function getEasyjetV2AllFlights(_departureSelected: string) {
    var _output: CustomFlight[] = [];

    const _easyjetV2AiportResponse = await getEasyjetV2Airports();
    const _isEasyjetAirport = _easyjetV2AiportResponse?.find((a) => a.code === _departureSelected);
    var _arrivalURLEasyjet = stringFormat(easyjetArrivalUrl, _departureSelected);

    const _easyjetResponse: EasyjetResponseRoutes = await ManageEasyjetRouteSessionData(_departureSelected!, _arrivalURLEasyjet);

    if (_isEasyjetAirport) {
        _easyjetResponse.Routes.forEach((route) => {
            let _easyjetResponseithoutReturn = route.flights.filter((name) => name.returnDate == null);
            let _easyjetResponseithoutReturnUnique = _easyjetResponseithoutReturn.filter((value, index, self) => index === self.findIndex((t) => t.date === value.date && t.price === value.price));

            _easyjetResponseithoutReturnUnique.forEach((flight) => {
                if (!flight.returnDate) {
                    var _easyjetCustomFlight: CustomFlight = {
                        Date: moment(flight.date + " " + flight.depTime, "YYYY-MM-DD HH:mm").toDate(),
                        Destination: _easyjetResponse.Airports.find((a) => a.AirportCode === route.destination)?.NameContent.AirportName!,
                        Origin: route.origin,
                        Price: flight.price,
                        Currency: currency,
                        Airline: Airline.Easyjet,
                        DateHasNoTme: false,
                    };
                    _output.push(_easyjetCustomFlight);
                }
            });
        });
    }

    return _output!;
}
//#endregion V2
