import styled from "styled-components";
import { mediaQuery } from "../Helpers/Helpers";

interface Props {
    text: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isDisabled?: boolean;
}

const Wrapper = styled.div<{}>`
    padding: 5px;
    margin: 5px;
    width: fit-content;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        padding: 0px;
    }
`;

const MyButton = styled.button<{}>`
    background-color: #ffffff;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    outline: none;
    padding: 7px 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;

    &:active,
    &:hover {
        background-color: #f7f7f7;
        border-color: #000000;
        transform: scale(0.96);
    }

    &:focus {
        box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
        transition: box-shadow 0.2s;
    }

    &:disabled {
        border-color: #dddddd;
        color: #dddddd;
        cursor: not-allowed;
        opacity: 1;
    }

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        font-size: 12px;
        padding: 5px;
        line-height: normal;
    }
`;

export const SquirrelButton: React.FC<Props> = ({ text, onClick, isDisabled }) => {
    return (
        <>
            <Wrapper>
                {isDisabled ? (
                    <MyButton onClick={onClick} disabled>
                        {text}
                    </MyButton>
                ) : (
                    <MyButton onClick={onClick}>{text}</MyButton>
                )}
            </Wrapper>
        </>
    );
};
