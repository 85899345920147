import { MediaSize } from "../types/CustomTypes";

const LOCALURL = "http://localhost:4001/squirrel_api";
const FLYIOURL = "https://squirrel-groundsevices.fly.dev/squirrel_api";

export const apiURL = FLYIOURL;

export const currency = "EUR";
export const apiDelay = 400;
export const isPhoneScreen = window.innerWidth <= MediaSize.phone;
export const isTabletScreen = window.innerWidth <= MediaSize.tablet && window.innerWidth > MediaSize.phone;
export const isDesktopScreen = window.innerWidth > MediaSize.tablet;
export const dateFormatWithTime = "YYYY-MM-DD HH:mm";
export const dateFormatOnly = "YYYY-MM-DD";

export const ryanairAirportUrl = "https://www.ryanair.com/api/views/locate/5/airports/en/active";
export const easyjetAirportUrl = "https://www.easyjet.com/ejcms/cache15m/api/flights/search?AllDestinations=true&AllOrigins=true&AssumedPassengersPerBooking=1&AssumedSectorsPerBooking=1&MaxResults=9999999";
export const easyjetAirportUrlV2 = "https://www.easyjet.com/funnel/api/query/search/airports";
export const vuelingAirportUrl = "https://apiwww.vueling.com/api/Stations/GetAllStationsWithCountryName?culture=en-gb";

export const ryanairArrivalUrl = "https://www.ryanair.com/api/views/locate/searchWidget/routes/en/airport/{0}";
export const easyjetArrivalUrl = "https://www.easyjet.com/ejcms/cache2m/api/inspireme/getroutedata?currencyId=0&originIata={0}";
export const vuelingArrivalUrl = "https://apiwww.vueling.com/api/Markets/GetAllMarketsSearcher";
export const eurowinArrivalUrl = "https://www.eurowings.com/en/discover/destinations/flight-routes/_jcr_content/main/flightplan.data.compCacheFlightPlan.origin{0}.date{2024-06}.json";

export const ryanairRouteUrl = "https://www.ryanair.com/api/farfnd/v4/oneWayFares/{0}/{1}/cheapestPerDay?outboundMonthOfDate={2}&currency=EUR";
export const easyjetRouteUrl = "https://www.easyjet.com/ejavailability/api/v81/fps/lowestdailyfares?ArrivalIata={1}&Currency=EUR&DateFrom={2}&DateTo={3}&DepartureIata={0}&InboundFares=true";
export const vuelingRouteUrl = "https://apiwww.vueling.com/api/FlightPrice/GetAllFlights?originCode={0}&destinationCode={1}&year={2}&month={3}&currencyCode=EUR&monthsRange=12";

//export const ryanairAvailabilityUrl = "https://www.ryanair.com/api/booking/v4/pt-pt/availability?ADT=1&TEEN=0&CHD=0&INF=0&Origin=OPO&Destination=FNC&promoCode=&IncludeConnectingFlights=false&DateOut=2024-07-06&DateIn=2024-07-08&FlexDaysBeforeOut=2&FlexDaysOut=2&FlexDaysBeforeIn=2&FlexDaysIn=2&RoundTrip=true&ToUs=AGREED";
export const ryanairAllDestinations = "https://www.ryanair.com/api/farfnd/v4/oneWayFares?departureAirportIataCode={0}&outboundDepartureDateFrom={1}&outboundDepartureDateTo={2}";
export const vuelingAllDestinations = "https://apiwww.vueling.com/api/Routes/GetRoutesWithPriceByOrigin?origin={0}&months=24&cache=cacheApi3&startDate={1}&currency=EUR&culture=pt-PT";
export const vuelingAllDestinationsKEY = "&destinations={0}";
export const vuelingAllDestinationsMomentDateFormat = "MM/DD/yyyy";
//easyjet all destinations comes in the airport url

export const easyjetFareUrl =
    "https://www.easyjet.com/ejavailability/api/v81/availability/query?AdultSeats=1&ArrivalIata={1}&DepartureIata={0}&IncludeLowestFareSeats=true&IncludePrices=true&LanguageCode=EN&MaxDepartureDate={2}&MinDepartureDate={3}";

export const easyjetDestinationCountriesUrl = "https://www.easyjet.com/holidays/_api/v1.0/destinations/countries";
export const easyjetAvailabilityUrl = "https://www.easyjet.com/homepage/api/availability?origin={0}&destination={1}&currency=EUR&isReturn=true&startDate={2}&endDate={3}&isWorldwide=false";
